import config from '@/utils/config'
import RequestAdmin from '@/utils/request'
import { formatDate } from '@/utils/fechas'

const pruebas = [
  {
    id: 1,
    nombre: 'ON OFF',
    companyId: '659779a0d7899212df1ee296',
    user: 'soporte@apmix.com',
    active: true,
  },
  {
    id: 2,
    nombre: 'SILICON',
    companyId: '659779a0d7899212df1ee296',
    user: 'soporte@apmix.com',
    active: true,
  },
  {
    id: 19,
    nombre: 'CONTIENE QR',
    companyId: '659779a0d7899212df1ee296',
    user: 'soporte@apmix.com',
    active: true,
  },
]

const destinosNema = [
  {
    id: 1,
    nombre: 'Pruebas Exitosas',
  },
  {
    id: 2,
    nombre: 'Pruebas Fallidas',
  },
]

const voltajes = [
  {
    id: 1,
    nombre: '25',
    value: 25,
    companyId: '659779a0d7899212df1ee296',
    user: 'soporte@apmix.com',
    active: true,
  },
  {
    id: 2,
    nombre: '65',
    value: 65,
    companyId: '659779a0d7899212df1ee296',
    user: 'soporte@apmix.com',
    active: true,
  },
  {
    id: 3,
    nombre: '90',
    value: 90,
    companyId: '659779a0d7899212df1ee296',
    user: 'soporte@apmix.com',
    active: true,
  },
  {
    id: 4,
    nombre: '165',
    value: 165,
    companyId: '659779a0d7899212df1ee296',
    user: 'soporte@apmix.com',
    active: true,
  },
]

// eslint-disable-next-line consistent-return
export function getUtilsNemas(tipo) {
  if (tipo === 2) return pruebas
  if (tipo === 3) return destinosNema
  if (tipo === 4) return voltajes
}

const url = `${config.URL_BASE}nemas`

export async function updateCreateNema(body, tipo) {
  try {
    const request = new RequestAdmin()
    if (tipo === 1) {
      return request.executePostPutPatch(`${url}/${body.id}`, body, 'PATCH')
    }
    // eslint-disable-next-line no-param-reassign
    delete body.id
    return request.executePostPutPatch(`${url}`, body, 'POST')
  } catch (err) {
    console.error('Error en Crear / Editar Luminaria', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function findNemas(filter) {
  try {
    const request = new RequestAdmin()
    const urrl = `${url}?filter=${JSON.stringify(filter)}`
    return request.executeGet(urrl)
  } catch (err) {
    console.error('Error en obtener Luminarias', err)
    return null
  }
}

export async function countNemas(where) {
  try {
    const request = new RequestAdmin()
    return request.executeGet(`${url}/count?where=${JSON.stringify(where)}`)
  } catch (err) {
    console.error('Error en obtener Luminarias', err)
    return null
  }
}

export async function getNema(id) {
  try {
    if (id === null || id === undefined) return null
    const request = new RequestAdmin()
    return await request.executeGet(`${url}/${id}`)
  } catch (err) {
    console.error('Error en cargar Luminarias', err)
    return null
  }
}
export async function generarReporte(datosNemas) {
  const html = `
    <!DOCTYPE html>
    <html lang="es">
<head>
  <meta charset="utf-8">
  <meta name="factura" content="width=device-width, initial-scale=1">
  <title>REPORTE DE DESPACHO</title>
  <meta charset="utf-8">
</head>
<style>
    @page {
      margin-top: 1.1811in;
      margin-bottom: 0.7874in;
      margin-left: 0.7874in;
      margin-right: 0.7874in;
    }
    .headerLogos{
        width: 100%;
        margin-bottom: 5px;
        margin-top: 25px;
        margin-bottom: 20px;
        border-collapse: collapse;
    }
    .headerTitulos{
        background-color: #1f88c2;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        color: white;
        font-family: sans-serif;
        text-align: justify;
    }
    .titulosDatosColores {
        background-color: #1f88c2;
        color: yellow;
        font-family: sans-serif;
        padding: 3px;
        width: 300px;
    }
    td {
      border: 1px solid black;
      padding: 8px;
      margin-right: auto;
      margin-left: auto;
      text-align: left;
      font-family: sans-serif;
      font-size: 1.4em;
    }
    th {
      border: 1px solid black;
      color: yellow;
      margin-right: 25px;
      margin-left: 25px;
      font-family: sans-serif;
      text-align: center;
      font-weight: bold;
      font-size: 1.5em;
    }
    table {
      border-collapse: collapse;
      width: 100%;
    }
    p {
      font-family: sans-serif;
      font-weight: bold;
    }
    .Imagenes{
        width: 100%;
        border-collapse: collapse;
    }
    .table {
        border: 1px solid black;
        border-collapse: collapse;
        width: 100%;
        padding-right: 50px;
    }
    .lineaFirma {
        border-top: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }
    .lineaFirma3 {
        border-top: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .lineaFirma{
        border-top: 1px solid black;
        width: 25%;
        display: flex;
        margin-top: 150px;
        font-family: arial,serif;
    }
    .linea {
        position: relative;
        margin-top: 40px;
        width: 100%;
    }
    .lineas {
            position: relative;
            width: 100%;
    }
    .linea hr {
        border: none;
        border-top: 1px solid black;
        margin-bottom: 15px;
    }
    .texto {
        text-transform: uppercase;
        text-align: center;
        font-family: arial,serif;
    }
    .centro {
        display: flex;
    }
    .linea span {
        width: 80%;
        position: absolute;
        top: -15px;
        transform: translate(-50%, -50%);
    }
    .lineas hr {
        border: none;
        border-top: 1px solid black;
        margin-bottom: 15px;
    }
    .lineas span {
        width: 80%;
        position: absolute;
        top: -15px;
        transform: translate(-50%, -50%);
    }
    .tituloDatosImagenes {
      background-color: #1f88c2;
      color: yellow;
      font-family: sans-serif;
      font-weight: bold;
      width: 38.5%;
      text-align: center;
      font-size: 1em;
      padding: 10px;
      margin: 0 auto;
      border: 1px solid black;
    }
</style>
<body>
<table class="headerLogos">
  <tr>
    <th width="33.33%" height="20px">
      <img style="max-width: 180px;" src="https://apsistema.com/img/apmixlogo.68a26a4f.png" alt="logo apmix"/>
    </th>
    <th width="66.64%" height="20px" class="headerTitulos"> <center>Reporte de Nema<br>Dirección de Servicios Públicos<br>Municipalidad de Mixco</center></th>
  </tr>
</table>
<table style="width: 100%;" class="table">
  <tr>
    <th style="flex: 1;" class="titulosDatosColores">INFORMACIÓN GENERAL DE LA NEMA</th>
  </tr>
</table>
<table>
  <thead>
    <tr>
      <th style="width: 25%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">POTENCIA</th>
      <th style="width: 25%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">TIPO</th>
      <th style="width: 25%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">SERIE</th>
      <th style="width: 25%; color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">LOTE</th>
    </tr>
  </thead>
  </table>
  <table>
    <tr>
      <td style="width: 25%">
        <center>
          ${datosNemas.potencia.nombre}
        </center>
      </td>
      <td style="width: 25%">
        <center>
        ${datosNemas.tipo}
        </center>
      </td>
      <td style="width: 25%">
        <center>
        ${datosNemas.serie}
        <center>
      </td>
      <td style="width: 25%">
        <center>
          ${datosNemas.lote}
        </center>
      </td>
    </tr>
  </table>
<br>
<table style="width: 100%;" class="table">
  <tr>
    <th style="flex: 1;" class="titulosDatosColores">INFORMACIÓN DEL TRACKING</th>
  </tr>
</table>
<table>
  <thead>
    <tr>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">FECHA</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">USUARIO</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">ESTADO</th>
    </tr>
  </thead>
  <tbody>
    ${datosNemas.tracking.map((nema, index) => `
        <tr>
          <td style="width: 10%">
            <center>
            ${formatDate(nema.fecha, '/')}
            </center>
          </td>
          <td style="width: 15%">
            <center>
              ${nema.usuario.nombre}
            </center>
          </td>
          <td style="width: 15%">
            <center>
              ${nema.estado}
            </center>
          </td>
        </tr>
      `).join('')}
  </tbody>
</table>
<br>
<table style="width: 100%">
  <tbody>
    ${datosNemas.imagenes.map((nema, index) => `
        <tr>
          <td style="border: none; text-align: center;">
            <div class="tituloDatosImagenes">FOTOGRAFÍA ${nema.tipo}</div>
            <div style="max-width: 40%; max-height: 40%; margin: 0 auto;">
              ${nema.url ? `<img src="${nema.url}" style="width: 100%; height: 100%; object-fit: cover;" alt="Imagen de la luminaria">` : 'No hay imagen'}
            </div>
          </td>
        </tr>
      `).join('')}
  </tbody>
</table>
<br>
<br>
<br>
<br>
<br>
</div>
</body>
</html>
  `

  const body = {
    tamaño: 'A2',
    margenes: {
      top: '2cm',
      right: '2cm',
      bottom: '1cm',
      left: '2cm',
    },
    orientation: 'horizontal',
    contenido: html,
  }
  const request = new RequestAdmin()
  const result = await request.executePostPutPatch('https://api.apsistema.com/documentos', body, 'POST')
  return result
}
