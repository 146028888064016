import RequestAdmin from '@/utils/request'
import { formatDate } from '@/utils/fechas'
import moment from 'moment/moment'
import config from '@/utils/config'
import { findLuminariasLab, updateCreateLuminariaLab } from '@/utils/laboratorio/luminarias'
import { mensajeError, notificacionError, notificacionInformativa } from '@/utils/mensajes'

const estados = {
  INGRESADA: {
    nombre: 'LUMINARIA INGRESADA',
    value: 'INGRESADA',
  },
  REPARACIÓN: {
    nombre: 'LUMINARIA EN REPARACIÓN (APVN)',
    value: 'REPARACIÓN',
  },
  GARANTÍA: {
    nombre: 'LUMINARIA EN GARANTÍA',
    value: 'GARANTÍA',
  },
  REPARADA: {
    nombre: 'LUMINARIA REPARADA',
    value: 'REPARADA',
  },
  MANTENIMIENTO: {
    nombre: 'LUMINARIA REPARADA',
    value: 'MANTENIMIENTO DE LUMINARIA',
  },
  DESPACHO: {
    nombre: 'LUMINARIA LISTA PARA DESPACHO',
    value: 'LUMINARIA PARA DESPACHO',
  },
  DESPACHADA: {
    nombre: 'LUMINARIA DESPACHADA',
    value: 'LUMINARIA DESPACHADA',
  },
  DESCARTADA: {
    nombre: 'LUMINARIA DESCARTADA',
    value: 'LUMINARIA DESCARTADA',
  },
}

const opcionesReporte = [
  { id: 1, nombre: 'Disponibilidad a la Red', nombreReporte: 'DISPONIBILIDAD A LA RED' },
  { id: 2, nombre: 'Mantenimiento Correctivo', nombreReporte: 'MANTENIMIENTO CORRECTIVO' },
  { id: 3, nombre: 'Ampliaciones', nombreReporte: 'AMPLIACIONES' },
  { id: 4, nombre: 'Mejoras APVN', nombreReporte: 'MEJORAS APVN' },
]

const destinosluminariass = [
  {
    id: '1',
    nombre: 'Se envía a Laboratorio (APVN)',
  },
  {
    id: '2',
    nombre: 'Se envía a Garantía',
  },
  {
    id: '3',
    nombre: 'Descartar Luminaria',
  },
]

const opcionesPinturaDespacho = [
  {
    id: '1',
    nombre: 'Requiere Pintura',
  },
  {
    id: '2',
    nombre: 'Queda Reparada',
  },
]

const pruebasluminariass = [
  {
    id: '1',
    nombre: 'Pruebas Exitosas',
    exitosa: true,
  },
  {
    id: '2',
    nombre: 'Pruebas Fallidas',
    exitosa: false,
  },
]

const potencias = [
  {
    nombre: '30',
    valor: 30,
  },
  {
    nombre: '50',
    valor: 50,
  },
  {
    nombre: '80',
    valor: 80,
  },
  {
    nombre: '150',
    valor: 150,
  },
]

const antena = [
  {
    id: '1',
    nombre: 'Si',
  },
  {
    id: '2',
    nombre: 'No',
  },
  {
    id: '3',
    nombre: 'N/A',
  },
]

const tipos = [
  {
    id: '1',
    nombre: 'SLIM1',
  },
  {
    id: '2',
    nombre: 'SLIM2',
  },
  {
    id: '3',
    nombre: 'SLIM2P',
  },
  {
    id: '4',
    nombre: 'EFM',
  },
  {
    id: '5',
    nombre: 'PICNEMA',
  },
]

export const variantesEstado = {
  INGRESADA: 'light-danger',
  REPARACIÓN: 'light-warning',
  GARANTÍA: 'light-warning',
  REPARADA: 'light-primary',
  'MANTENIMIENTO DE LUMINARIA': 'light-secondary',
  'LUMINARIA PARA DESPACHO': 'light-info',
  'LUMINARIA DESPACHADA': 'light-success',
}

export async function creacionLuminaria(idReparacion, luminaria, usuario, origen, modelo, pruebasSeleccionadas, reparaciones) {
  try {
    let luminariaExistenteEncontrada = null

    if (luminaria.idLuminaria) {
      // Buscar por idLuminaria si está proporcionado
      const filtroId = {
        where: {
          companyId: usuario.company.id,
          id: luminaria.idLuminaria,
        },
      }
      luminariaExistenteEncontrada = await findLuminariasLab(filtroId)
    }

    if (!luminariaExistenteEncontrada || luminariaExistenteEncontrada.length === 0) {
      // Si no se encontró por idLuminaria, buscar por serie
      const filterSerie = {
        where: {
          companyId: usuario.company.id,
          serie: luminaria.codigo,
        },
      }
      luminariaExistenteEncontrada = await findLuminariasLab(filterSerie)
    }

    const nuevoHistorial = {
      idReparacion,
      idPunto: luminaria.poste.id ? luminaria.poste.id : '',
      noPoste: luminaria.poste,
      tipo: luminaria.tipoLampara.nombre,
      tipoLampara: luminaria.modelo,
      serie: luminaria.codigo,
      potencia: luminaria.potencia,
      fechaCreacion: new Date(),
      usuarioCreacion: luminaria.usuarioCreacion,
      companyId: usuario.company.id,
    }
    let nuevoSeguimiento = null
    if (origen === 'REPARADA') {
      let luminariaReparada = luminaria.seguimiento.find(seg => seg.estado === 'Luminaria Reparada')
      if (!luminariaReparada) {
        luminariaReparada = luminaria.seguimiento.find(seg => seg.estado === 'Reparación de Luminaria')
      }
      const primeraFotoUrl = luminaria.seguimiento?.[0]?.tracking?.[0]?.url || luminaria.seguimiento?.[0]?.url || ''
      nuevoSeguimiento = {
        estado: 'Detalles de la Reparación',
        fecha: new Date(),
        foto: {
          nombre: 'Fotografía de Ingreso a Bodega',
          url: primeraFotoUrl,
        },
        fallas: luminaria.fallas,
        preDiagnosticos: luminaria.preDiagnosticos,
        pruebas: pruebasSeleccionadas,
        reparaciones: luminaria.reparaciones || reparaciones,
        destinoLuminaria: luminaria.seguimiento[1].destinoLuminaria,
        observacionesReparacion: luminariaReparada ? luminariaReparada.observaciones : '',
        observacionesPruebas: luminariaReparada ? luminariaReparada.observacionesPruebas : '',
      }
    }
    let validacionEstado
    if (origen === 'INGRESO') {
      validacionEstado = 'REPARACION'
    } else if (origen === 'REPARADA') {
      validacionEstado = 'DESPACHO'
    }
    let bodyLuminaria = {
      tipo: luminaria.modeloLampara.nombre,
      tipoLampara: modelo || luminariaExistenteEncontrada.tipoLampara || luminaria.modelo,
      serie: luminaria.codigo,
      potencia: luminaria.potencia,
      estado: validacionEstado,
      lote: '',
      tracking: [],
      imagenes: [],
      seguimiento: nuevoSeguimiento ? [nuevoSeguimiento] : [],
      fechaCreacion: new Date(),
      usuarioCreacion: luminaria.usuarioCreacion,
      companyId: usuario.company.id,
    }
    if (luminariaExistenteEncontrada && luminariaExistenteEncontrada.length > 0) {
      const luminariaExistente = luminariaExistenteEncontrada[0]
      bodyLuminaria = {
        ...bodyLuminaria,
        fechaCreacion: luminariaExistente.fechaCreacion,
        usuarioCreacion: luminariaExistente.usuarioCreacion,
        historialReparaciones: luminariaExistente.historialReparaciones ? [...luminariaExistente.historialReparaciones, nuevoHistorial] : [nuevoHistorial],
        // eslint-disable-next-line no-nested-ternary
        seguimiento: luminariaExistente.seguimiento ? [...luminariaExistente.seguimiento, ...(nuevoSeguimiento ? [nuevoSeguimiento] : [])] : (nuevoSeguimiento ? [nuevoSeguimiento] : []),
      }
      await updateCreateLuminariaLab({ ...bodyLuminaria, id: luminariaExistente.id }, 1)
      notificacionInformativa('Se actualizó la luminaria en el módulo de inventario!', 'Luminaria ingresada a mantenimiento y actualizada en su módulo.')
    } else {
      await updateCreateLuminariaLab(bodyLuminaria, 2)
      notificacionInformativa('Luminaria creada en el módulo de inventario!', 'La luminaria se ha registrado correctamente en el sistema.')
    }
    return true
  } catch (error) {
    console.error(error)
    mensajeError('Algo ha salido mal, intenta nuevamente!')
    return false
  }
}

export async function descartarLuminaria(luminaria, usuario, fotos, comentarios) {
  try {
    let luminariaEncontrada = null
    if (luminaria.idLuminaria) {
      // Buscar por idLuminaria si está proporcionado
      const filtroId = {
        where: {
          companyId: usuario.company.id,
          id: luminaria.idLuminaria,
        },
      }
      luminariaEncontrada = await findLuminariasLab(filtroId)
    }

    if (!luminariaEncontrada || luminariaEncontrada.length === 0) {
      // Si no se encontró por idLuminaria, buscar por serie
      const filterSerie = {
        where: {
          companyId: usuario.company.id,
          serie: luminaria.codigo,
        },
      }
      luminariaEncontrada = await findLuminariasLab(filterSerie)
    }

    const fotosModificado = {
      ...fotos,
      fecha: new Date(),
    }
    let bodyLuminaria
    if (luminariaEncontrada && luminariaEncontrada.length > 0) {
      const luminariaExistente = luminariaEncontrada[0]
      // Si se encontró una luminaria existente, actualizarla
      bodyLuminaria = {
        id: luminariaExistente.id,
        estado: 'DESCARTADA',
        seguimiento: luminariaExistente.seguimiento ? [...luminariaExistente.seguimiento, fotosModificado] : [fotosModificado],
        fechaCreacion: luminariaExistente.fechaCreacion,
        usuarioCreacion: luminariaExistente.usuarioCreacion,
        companyId: usuario.company.id,
      }
      await updateCreateLuminariaLab(bodyLuminaria, 1)
      notificacionInformativa('Luminaria actualizada y descartada!', 'La luminaria ha sido actualizada y marcada como descartada.')
    } else {
      // Si no se encontró ninguna luminaria, crear una nueva con estado "DESCARTADA"
      bodyLuminaria = {
        tipo: luminaria.modeloLampara?.nombre || '',
        tipoLampara: luminaria.modelo || '',
        serie: luminaria.codigo,
        potencia: luminaria.potencia,
        estado: 'DESCARTADA',
        lote: '',
        tracking: [],
        imagenes: [],
        seguimiento: [fotosModificado],
        fechaCreacion: new Date(),
        usuarioCreacion: luminaria.usuarioCreacion,
        companyId: usuario.company.id,
      }
      await updateCreateLuminariaLab(bodyLuminaria, 2)
      notificacionInformativa('Luminaria creada y descartada!', 'La luminaria ha sido registrada en el sistema y marcada como descartada.')
    }
  } catch (error) {
    console.error(error)
    notificacionError('Algo ha salido mal, intentalo nuevamente! :(')
  }
}

export function calcularSemanaDeCreacion(fechaCreacion) {
  const fecha = moment(fechaCreacion)
  const numeroDeSemana = fecha.isoWeek()

  return `${numeroDeSemana}`
}
export function getListadoFallasLuminaria(tipo) {
  if (tipo === 1) return destinosluminariass
  if (tipo === 2) return potencias
  if (tipo === 3) return antena
  if (tipo === 4) return tipos
  if (tipo === 5) return pruebasluminariass
  if (tipo === 6) return estados
  if (tipo === 7) return opcionesReporte
  if (tipo === 8) return opcionesPinturaDespacho
  return []
}

const url = `${config.URL_BASE}falla-lampara`
export async function updateCreateLuminaria(body, tipo) {
  try {
    const request = new RequestAdmin()
    if (tipo === 1) {
      return request.executePostPutPatch(`${url}/${body.id}`, body, 'PATCH')
    }
    // eslint-disable-next-line no-param-reassign
    delete body.id
    return request.executePostPutPatch(`${url}`, body, 'POST')
  } catch (err) {
    console.error('Error en Crear / Editar luminarias', err)
    return null
  }
}
// eslint-disable-next-line import/prefer-default-export
export async function findLuminarias(filter) {
  try {
    const request = new RequestAdmin()
    const urrl = `${url}?filter=${JSON.stringify(filter)}`
    return request.executeGet(urrl)
  } catch (err) {
    console.error('Error al obtener luminarias', err)
    return null
  }
}

export async function getLuminaria(id) {
  try {
    if (id === null || id === undefined) return null
    const request = new RequestAdmin()
    return await request.executeGet(`${url}/${id}`)
  } catch (err) {
    console.error('Error en cargar luminarias', err)
    return null
  }
}

function obtenerNombresDeFallas(luminarias) {
  if (Array.isArray(luminarias.fallas)) {
    const nombresDeFallas = luminarias.fallas.map(falla => falla.nombre)

    return nombresDeFallas.join(', ')
  }

  return 'No hay datos de fallas'
}

function getNombresPrediagnosticos(luminaria) {
  if (!luminaria || !luminaria.preDiagnosticos) {
    return 'Se envió a garantía, no hay pre diagnósticos seleccionados'
  }
  const preDiagnosticosSeleccionados = luminaria.preDiagnosticos.filter(prediagnostico => prediagnostico.fallasSeleccionadas)
  return preDiagnosticosSeleccionados.map(prediagnostico => prediagnostico.nombre).join(', ')
}
function getNombresReparaciones(luminaria) {
  if (!luminaria.reparaciones || luminaria.reparaciones.length === 0) {
    return ''
  }

  return luminaria.reparaciones.map(reparaciones => reparaciones.nombre).join(', ')
}
function getNombresPruebas(luminaria) {
  const pruebasSeleccionadas = luminaria.pruebas.filter(prueba => prueba.pruebasSeleccionadas)
  return pruebasSeleccionadas.map(pruebas => pruebas.nombre).join(', ')
}
export async function generarReporte(luminaria) {
  const nombreFallas = obtenerNombresDeFallas(luminaria)
  const nombrePrediagnosticos = getNombresPrediagnosticos(luminaria)
  const nombreReparaciones = getNombresReparaciones(luminaria)
  const nombrePruebas = getNombresPruebas(luminaria)
  const html = `
    <!DOCTYPE html>
    <html lang="es">
<head>
  <meta charset="utf-8">
  <meta name="factura" content="width=device-width, initial-scale=1">
  <title>REPORTE DE MANTENIMIENTO DE LUMINARIAS</title>
  <meta charset="utf-8">
</head>
<style>
    @page {
      margin-top: 1.1811in;
      margin-bottom: 0.7874in;
      margin-left: 0.7874in;
      margin-right: 0.7874in;
    }
    .headerLogos{
      width: 100%;
      margin-bottom: 5px;
      margin-top: 15px;
      margin-bottom: 15px;
      border: 1px solid black;
      border-collapse: collapse;   
    }
    .headerTitulos{
      background-color: #1f88c2;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      color: white;
      font-family: sans-serif;
      text-align: justify;
      font-size: 1.5em;
    }
    .titulosDatosColores {
      border: 1px solid black;
      background-color: #1f88c2;
      color: yellow;
      font-family: sans-serif;
      padding: 3px;
      width: 300px;
    }
    .tituloDatosImagenes {
      background-color: #1f88c2;
      color: yellow;
      font-family: sans-serif;
      font-weight: bold;
      width: 97%;
      text-align: center;
      font-size: 1.5em;
      padding: 10px;
      border: 1px solid black;
    }
    .salto-pagina {
      page-break-before: always;
    }
    td{
      border-collapse: collapse;
      font-family: sans-serif;
      padding-right: 15px;
      padding-left: 15px;
      border: black;
    }
    th {
      font-family: sans-serif;
      text-align: center;
      font-weight: bold;
      font-size: 1.5em;
    }
    .tablaDatos {
      border: 1px solid black;
      border-collapse: collapse;
      width: 100%;
      padding-right: 50px;
    }
    .lineaFirma {
      border-top: 1px solid black;
      margin-left: auto;
      margin-right: auto;
      width: 40%;
    }
    .lineaFirma3 {
      border-top: 1px solid black;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
    .linea {
      position: relative;
      margin-top: 15px;
      width: 100%;
    }
    .lineas {
      position: relative;
      width: 100%;
    }
    .linea hr {
      border: none;
      border-top: 1px solid black;
      margin-bottom: 15px;
    }
    .texto {
      text-transform: uppercase;
      text-align: center;
      font-family: arial,serif;
    }
    .centro {
      display: flex;
    }
    .linea span {
      width: 80%;
      position: absolute;
      top: -15px;
      transform: translate(-50%, -50%);
    }
    .lineas hr {
      border: none;
      border-top: 1px solid black;
      margin-bottom: 15px;
    }
    .lineas span {
      width: 80%;
      position: absolute;
      top: -15px;
      transform: translate(-50%, -50%);  
    }
    .tablaImagenesVFOR {
      border-collapse: collapse;
      width: 100%;
    }
    .tablaImagenesVFOR td {
      width: 33.33%;
      padding: 10px;
      box-sizing: border-box;
    }
    .tablaImagenesVFOR img {
      width: 100%;
      max-height: 400px;
      object-fit: cover;
      margin-bottom: 15px;
    }
</style>
<body>
<table class="headerLogos">
  <tr>
    <th width="66.64%" height="20px" class="headerTitulos"> <center>Reporte Mantenimiento de Luminaria<br>Dirección de Servicios Publicos<br>Municipalidad de Villa Nueva</center></th>
    <th width="33.33%" height="20px">
      <img style="max-width: 180px;" src="https://storage.googleapis.com/apvn-files/imagenes/inspecciones/logo-apvn.jpg" alt="logoapvn"/>
    </th>
  </tr>
</table>
<table class="tablaDatos">
  <tr>
    <th class="titulosDatosColores">INFORMACIÓN GENERAL</th>
  </tr>
</table>
<table class="tablaDatos">
  <tr>
    <td style="margin-left: 15px; width: 13%; font-size:10.0pt;">POSTE DE REFERENCIA</td>
    <td>
      <div class="linea">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt">${luminaria.numeroPoste}</div>
        <hr>
      </div>
    </td>
    <td style="margin-left: 15px; width: 13%; font-size:10.0pt;">SEMANA</td>
    <td>
      <div class="linea">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt;">${calcularSemanaDeCreacion(luminaria.fechaCreacion)}</div>
        <hr>
      </div>
    </td>
    <td style="margin-left: 15px; width: 13%; font-size:10.0pt;">FECHA INGRESO</td>
    <td>
      <div class="linea">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt">${formatDate(luminaria.fechaCreacion, '/')}</div>
        <hr>
      </div>
    </td>
    <td style="margin-left: 15px; width: 13%; font-size:10.0pt;">DESTINO LUMINARIA</td>
    <td>
      <div class="linea">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt">${luminaria.seguimiento[1].destinoLuminaria.id === '1' ? 'APVN' : 'JUGANU'}</div>
        <hr>
      </div>
    </td>
  </tr>
</table>
<br>
<br>
<table class="tablaDatos">
  <tr>
    <th class="titulosDatosColores">MODELO</th>
    <th class="titulosDatosColores">TIPO</th>
    <th class="titulosDatosColores">GRUPO</th>
  </tr>
  <tr>
    <th>
      <div class="lineas">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 10px">${luminaria.modeloLampara.nombre}</div>
        <hr>
      </div>
    </th>
    <th>
      <div class="lineas">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 10px">${luminaria.tipoLampara.nombre}</div>
        <hr>
      </div>
    </th>
    <th>
      <div class="lineas">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 10px">${luminaria.grupo.nombre}</div>
        <hr>
      </div>
    </th>
  </tr>
</table>
<br>
<br>
<table class="tablaDatos">
  <tr>
    <th class="titulosDatosColores">POTENCIA</th>
    <th class="titulosDatosColores">ANTENA</th>
    <th class="titulosDatosColores">NIDO</th>
  </tr>
  <tr>
    <th>
      <div class="lineas">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 10px">${luminaria.potencia.nombre}</div>
        <hr>
      </div>
    </th>
    <th>
      <div class="lineas">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 10px">${luminaria.antena.nombre}</div>
        <hr>
      </div>
    </th>
    <th>
      <div class="lineas">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 10px">${luminaria.nido.nombre}</div>
        <hr>
      </div>
    </th>
  </tr>
</table>
<br>
<br>
<table class="tablaDatos">
  <tr>
    <th class="titulosDatosColores">FALLAS</th>
    <th class="titulosDatosColores">SERIE</th>
  </tr>
  <tr>
    <th>
      <div class="lineas">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 10px">${nombreFallas}</div>
        <hr>
      </div>
    </th>
    <th>
      <div class="lineas">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 10px">${luminaria.codigo}</div>
        <hr>
      </div>
    </th>
  </tr>
</table>
<br>
<br>
<table style="width: 100%;" class="tablaDatos">
  <tr>
    <th style="flex: 1;" class="titulosDatosColores">PREDIAGNÓSTICOS DE LUMINARIA</th>
  </tr>
</table>
<table class="tablaDatos">
  <tr>
    <td>
      <div style="margin-top: 1%" class="linea">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt">${nombrePrediagnosticos}</div>
        <hr>
      </div>
    </td>
  </tr>
</table>
<br>
${luminaria.reparaciones ? `
<table style="width: 100%;" class="tablaDatos">
  <tr>
    <th style="flex: 1;" class="titulosDatosColores">REPARACIONES DE LUMINARIA</th>
  </tr>
</table>
<table class="tablaDatos">
  <tr>
    <td>
      <div style="margin-top: 1%" class="linea">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt">${nombreReparaciones}</div>
        <hr>
      </div>
    </td>
  </tr>
</table>
` : ''}
<br>
<table style="width: 100%;" class="tablaDatos">
  <tr>
    <th style="flex: 1;" class="titulosDatosColores">PRUEBAS DE LUMINARIA</th>
  </tr>
</table>
<table class="tablaDatos">
  <tr>
    <td>
      <div style="margin-top: 1%" class="linea">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt">${nombrePruebas}</div>
        <hr>
      </div>
    </td>
  </tr>
</table>
<br>
<table style="width: 100%;" class="tablaDatos">
  <tr>
    <th style="flex: 1;" class="titulosDatosColores">OBSERVACIONES DEL INGRESO DE LUMINARIA</th>
  </tr>
</table>
<table class="tablaDatos">
  <tr>
    <td>
      <div style="margin-top: 1%" class="linea">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt">${luminaria.observaciones}</div>
        <hr>
      </div>
    </td>
  </tr>
</table>
<br>
<br>
<br>
<br>
<br>
<br>
<div>
    <hr class="lineaFirma">
  </div>
  <div class="texto">
    <p>${luminaria.usuarioCreacion.nombre}<br>ALUMBRADO PÚBLICO DE VILLA NUEVA, S.A.</p>
  </div>
<br>
<div class="salto-pagina"/>
<br>
<table style="width: 100%;" class="table">
  <tr>
    <th style="flex: 1;" class="titulosDatosColores">ANEXOS</th>
  </tr>
</table>
<br>
<br>
<table class="tablaImagenesVFOR">
  <tbody>
    ${luminaria.seguimiento.map((item, index) => {
    const imagenesHTML = item.tracking.map(imagen => `
        <td>
          <div class="tituloDatosImagenes">Fotografía ${imagen.tipo}</div>
          <img src="${imagen.url}" alt="${`img_${index}`}_${imagen.name}">
        </td>`).join('')

    return `<tr>${imagenesHTML}</tr>`
  }).join('')}
  </tbody>
</table>
</div>
</body>
</html>
  `
  const body = {
    tamaño: 'A2',
    margenes: {
      top: '2cm',
      right: '2cm',
      bottom: '1cm',
      left: '2cm',
    },
    orientation: 'portrait',
    contenido: html,
  }
  const request = new RequestAdmin()
  const result = await request.executePostPutPatch('https://api.apsistema.com/documentos', body, 'POST')
  return result
}

export async function generarReporteLista(luminarias, seleccion) {
  const html = `
    <!DOCTYPE html>
    <html lang="es">
<head>
  <meta charset="utf-8">
  <meta name="factura" content="width=device-width, initial-scale=1">
  <title>REPORTE DE MANTENIMIENTO DE luminariasS</title>
  <meta charset="utf-8">
</head>
<style>
    @page {
      margin-top: 1.1811in;
      margin-bottom: 0.7874in;
      margin-left: 0.7874in;
      margin-right: 0.7874in;
    }
    .headerLogos{
        width: 100%;
        margin-bottom: 5px;
        margin-top: 25px;
        margin-bottom: 20px;
        border-collapse: collapse;
    }
    .headerTitulos{
        background-color: #1f88c2;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        color: white;
        font-family: sans-serif;
        text-align: justify;
    }
    .titulosDatosColores {
        background-color: #1f88c2;
        color: yellow;
        font-family: sans-serif;
        padding: 3px;
        width: 300px;
    }
    td {
      border: 1px solid black;
      padding: 8px;
      margin-right: auto;
      margin-left: auto;
      text-align: left;
      font-family: sans-serif;
      font-size: 1.4em;
    }
    th {
      border: 1px solid black;
      color: yellow;
      margin-right: 25px;
      margin-left: 25px;
      font-family: sans-serif;
      text-align: center;
      font-weight: bold;
      font-size: 1.5em;
    }
    table {
      border-collapse: collapse;
      width: 100%;
    }
    p {
      font-family: sans-serif;
      font-weight: bold;
    }
    .Imagenes{
        width: 100%;
        border-collapse: collapse;
    }
    .table {
        border: 1px solid black;
        border-collapse: collapse;
        width: 100%;
        padding-right: 50px;
    }
    .lineaFirma {
        border-top: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }
    .lineaFirma3 {
        border-top: 1px solid black;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .lineaFirma{
        border-top: 1px solid black;
        width: 25%;
        display: flex;
        margin-top: 150px;
        font-family: arial,serif;
    }
    .linea {
        position: relative;
        margin-top: 40px;
        width: 100%;
    }
    .lineas {
            position: relative;
            width: 100%;
    }
    .linea hr {
        border: none;
        border-top: 1px solid black;
        margin-bottom: 15px;
    }
    .texto {
        text-transform: uppercase;
        text-align: center;
        font-family: arial,serif;
    }
    .centro {
        display: flex;
    }
    .linea span {
        width: 80%;
        position: absolute;
        top: -15px;
        transform: translate(-50%, -50%);
    }
    .lineas hr {
        border: none;
        border-top: 1px solid black;
        margin-bottom: 15px;
    }
    .lineas span {
        width: 80%;
        position: absolute;
        top: -15px;
        transform: translate(-50%, -50%);
    }
    .checkmark {
      font-size: 20pt;
    }
</style>
<body>
<table class="headerLogos">
  <tr>
    <th width="33.33%" height="20px">
      <img style="max-width: 180px;" src="https://storage.googleapis.com/apvn-files/imagenes/inspecciones/logo-apvn.jpg" alt="logoapvn"/>
    </th>
    <th width="66.64%" height="20px" class="headerTitulos"> <center>Reporte de Despachos de Luminarias<br>Dirección de Servicios Públicos<br>Municipalidad de Villa Nueva</center></th>
  </tr>
</table>
<br>
<table class="Imagenes">
        <tr>
          <th class="titulosDatosColores">DISPONIBILIDAD A LA RED</th>
          <th class="titulosDatosColores">MANTENIMIENTO CORRECTIVO</th>
          <th class="titulosDatosColores">AMPLIACIONES</th>
          <th class="titulosDatosColores">MEJORAS APVN</th>
        </tr>
        <tr>
          <td>
            <div class="lineas">
              <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 10px">${
  seleccion && seleccion.nombreReporte === 'DISPONIBILIDAD A LA RED' ? '<span class="checkmark">X</span>' : ''
}
              </div>
              <hr style="margin-top: 30px">
            </div>
          </td>
          <td>
            <div class="lineas">
              <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 10px">
                ${
  seleccion && (seleccion.nombreReporte === 'MANTENIMIENTO CORRECTO' || seleccion.nombreReporte === 'MANTENIMIENTO CORRECTIVO')
    ? '<span class="checkmark">X</span>'
    : ''
}
              </div>
              <hr style="margin-top: 30px">
            </div>
          </td>
          <td>
            <div class="lineas">
              <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 10px">${
  seleccion && seleccion.nombreReporte === 'AMPLIACIONES' ? '<span class="checkmark">X</span>' : ''
}
              </div>
              <hr style="margin-top: 30px">
            </div>
          </td>
          <td>
            <div class="lineas">
              <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 10px">${
  seleccion && seleccion.nombreReporte === 'MEJORAS APVN' ? '<span class="checkmark">X</span>' : ''
}
              </div>
              <hr style="margin-top: 30px">
            </div>
          </td>
        </tr>
      </table>
<br>
<p>Mediante el siguiente documento, Yo <span contenteditable="true" style="border-bottom: 1px solid #000; display: inline-block; width: 28%;"></span>, Jefe de brigada Luxiola, hago entrega de las lámparas y accesorios de los mantenimientos realizados.</p>
<table>
  <thead>
    <tr>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">NO.</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">SERIE LÁMPARA</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">PONTENCIA</th>
      <th style="color: yellow; background-color: #1f88c2; font-family: sans-serif; text-align: center;">GRUPO</th>
    </tr>
  </thead>
  <tbody>
    ${luminarias.map((luminaria, index) => `
      <tr>
        <td>${index + 1}</td>
        <td>${luminaria.codigo}</td>
        <td>${luminaria.potencia.nombre}</td>
        <td>${luminaria.grupo.nombre}</td>
      </tr>
    `)
    .join('')}
  </tbody>
</table>
<br>
<br>
<p>Yo  <span contenteditable="true" style="border-bottom: 1px solid #000; display: inline-block; width: 20%;"></span>, encargado de bodega, firmo y acepto la devolución de Lámparas y Accesorios completos, correspondientes a los mantenimientos realizados.</p>
<br>
<br>
<br>
<p>Firma  <span contenteditable="true" style="border-bottom: 1px solid #000; display: inline-block; width: 28%;"></span></p>
<br>
<br>
<br>
<table class="Imagenes">
  <tr>
    <th class="titulosDatosColores">OBSERVACIONES</th>
  </tr> 
  <tr>
    <td>
      <div class="lineas">
        <div style="text-align:center; text-transform: uppercase; font-size:10.0pt; font-weight: normal; margin-top: 15%"></div>
      </div>
    </td>
  </tr>
  </tbody>
</table>
</div>
</body>
</html>
  `

  const body = {
    tamaño: 'A2',
    margenes: {
      top: '2cm',
      right: '2cm',
      bottom: '1cm',
      left: '2cm',
    },
    orientation: 'horizontal',
    contenido: html,
  }
  const request = new RequestAdmin()
  const result = await request.executePostPutPatch('https://api.apsistema.com/documentos', body, 'POST')
  return result
}
