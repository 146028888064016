import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'

export function mensajeError(mensaje) {
  Swal.fire({
    title: 'Error!',
    text: mensaje,
    icon: 'error',
    customClass: {
      confirmButton: 'btn btn-primary',
    },
    buttonsStyling: false,
  })
}

export function mensajeInformativo(title, text) {
  Swal.fire({
    title,
    text,
    icon: 'success',
    customClass: {
      confirmButton: 'btn btn-primary',
    },
    buttonsStyling: false,
  })
}

export function mensajeConfirmar(title, text, icon) {
  return Swal.fire({
    title,
    text,
    icon,
    showCancelButton: true,
    confirmButtonText: 'CONFIRMAR',
    cancelButtonText: 'CANCELAR',
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-outline-danger ml-1',
    },
    buttonsStyling: false,
  }).then(result => result.value)
}

export function notificacionInformativa(titulo, informacion) {
  Vue.prototype.$toast({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      title: titulo,
      icon: 'CheckIcon',
      variant: 'success',
      text: informacion,
    },
  })
}
export function notificacionEspera(titulo, informacion) {
  Vue.prototype.$toast({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      title: titulo,
      icon: 'ClockIcon',
      variant: 'warning',
      text: informacion,
    },
  })
}
export function notificacionError(titulo, informacion) {
  Vue.prototype.$toast({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      title: titulo,
      icon: 'XIcon',
      variant: 'danger',
      text: informacion,
    },
  })
}
