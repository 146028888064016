export const estiloTitulos = {
  font: {
    bold: true,
    size: 20,
    color: '#ffffff',
  },
  fill: {
    type: 'pattern',
    patternType: 'solid',
    fgColor: '#0070c0',
  },
  alignment: {
    horizontal: 'center',
    vertical: 'center',
    wrapText: true,
  },
}
export const estiloSubtitulos = {
  font: {
    bold: true,
    size: 10,
    color: '#ffffff',
  },
  fill: {
    type: 'pattern',
    patternType: 'solid',
    fgColor: '#0070c0',
  },
  alignment: {
    horizontal: 'center',
    vertical: 'center',
    wrapText: true,
  },
}
export const estiloColumns = {
  font: {
    bold: true,
    size: 12,
    color: '#ffffff',
  },
  fill: {
    type: 'pattern',
    patternType: 'solid',
    fgColor: '#0070c0',
  },
  alignment: {
    horizontal: 'center',
    vertical: 'center',
    wrapText: true,
    width: 15,
  },
  border: {
    top: {
      style: 'thin',
      color: { auto: 1 },
    },
    left: {
      style: 'thin',
      color: { auto: 1 },
    },
    bottom: {
      style: 'thin',
      color: { auto: 1 },
    },
    right: {
      style: 'thin',
      color: { auto: 1 },
    },
  },
}
export const estiloRows = {
  font: {
    size: 10,
  },
  alignment: {
    horizontal: 'center',
    vertical: 'center',
    wrapText: true,
  },
  border: {
    top: { style: 'thin', color: { auto: 1 } },
    left: { style: 'thin', color: { auto: 1 } },
    bottom: { style: 'thin', color: { auto: 1 } },
    right: { style: 'thin', color: { auto: 1 } },
  },
}
