<template>
  <div>
    <b-overlay
      opacity="0.80"
      variant="transparent"
      blur="0.40rem"
      :show="loading"
    >
      <template #overlay>
        <div
          class="text-center"
        >
          <feather-icon
            icon="LoaderIcon"
            size="40"
            variant="primary"
            class="rotate"
          />
          <p
            style="font-weight: bold; font-size: 1.2rem"
            class="text-primary"
          >
            POR FAVOR, ESPERE...
          </p>
        </div>
      </template>
      <b-row>
        <b-col
          :cols="isMobile ? 12 : 2"
        >
          <modal-ingreso-luminarias
            :origen="'Manual'"
            :potencias="potencias"
            :origen-empresa="origen"
            :tipos-lampara="tiposLamparas"
            @cerrar-ingreso="loadReport"
          />
        </b-col>
        <b-col
          :cols="isMobile ? 12 : 2"
          :md="origen === 'APVN' ? 3 : 2"
        >
          <div
            style="z-index: 1"
          >
            <label class="mr-1">Tipo de Lámpara: </label>
            <v-select
              v-model="tipoLampara"
              label="nombre"
              :options="tiposLamparas"
            />
          </div>
        </b-col>
        <b-col :cols="isMobile ? 8 : 2">
          <b-form-group>
            <label>Etapa:</label>
            <v-select
              v-model="etapaBusqueda"
              label="title"
              :options="etapas"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isMobile"
          cols="2"
        >
          <b-form-group>
            <label>Fecha Inicio:</label>
            <b-input-group class="mb-1">
              <b-form-input
                id="example-input"
                v-model="fechaInicio"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="fechaInicio"
                  show-decade-nav
                  button-only
                  size="sm"
                  right
                  locale="en-US"
                  aria-controls="example-input"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="!isMobile"
          cols="2"
        >
          <b-form-group>
            <label>Fecha Fin:</label>
            <b-input-group class="mb-1">
              <b-form-input
                id="fecha-fin"
                v-model="fechaFin"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="fechaFin"
                  show-decade-nav
                  button-only
                  size="sm"
                  right
                  locale="en-US"
                  aria-controls="example-input"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          style="margin-top: 23px"
          cols="1"
        >
          <table style="margin-left: -20px">
            <tr>
              <td>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.v-primary
                  title="Buscar"
                  variant="primary"
                  class="btn-icon"
                  @click="loadReport"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </td>
              <td>
                <componente-asignacion
                  :origen="'luminarias'"
                  :columnas-luminarias="columnsLuminaria"
                  :tecnicos="tecnicos"
                  :origen-reporte="origenReporte"
                  :grupos="grupos"
                  :opciones-reporte="opcionesReporte"
                  @actualizar="loadReport"
                />
              </td>
              <!--td v-if="origen !== 'APVN'">
                <b-overlay :show="loadingDespacho">
                  <b-button
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    v-b-tooltip.hover.v-primary
                    title="Descargar Reporte Lista PDF"
                    variant="primary"
                    class="btn-icon"
                    @click="generarDocumentoDespacho()"
                  >
                    <feather-icon icon="DownloadIcon" />
                  </b-button>
                </b-overlay>
              </td-->
              <td v-if="usuario.company.enabledReport">
                <reporte-general
                  :rows="rowsReporte"
                  :columns="columnsReporte"
                  :titulo-principal="tituloPrincipal"
                  :titulo-secundario="tituloSecundario"
                  :subtitulo="subtitulo"
                  :titulo-descarga="tituloDescarga"
                />
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-input
            v-model="textoEscaneado"
            type="text"
            class="textoQr qr-textarea"
            style="position: absolute; top: -9999px; width: 50%"
            @change="extraerCodigos"
          />
        </b-col>
        <b-col cols="12">
          <vue-good-table
            :columns="columnasSeleccionadas"
            :rows="rows"
            style-class="vgt-table condensed"
            :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
            :search-options="{
              enabled: true,
              placeholder: 'Buscar...'
            }"
            @on-row-dblclick="showDetalle"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'estado'">
                <b-badge :variant="getColorClass(props.row)">
                  {{ estadoSeguimiento(props.row.estado) }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'fechaCreacion'">
                <span>{{ calcularFecha(props.row.fechaCreacion) }}</span>
              </span>
              <span v-else-if="props.column.field === 'opciones'">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click="imprimirEtiqueta(props.row)"
                  >
                    <feather-icon
                      icon="PrinterIcon"
                      class="mr-50"
                    />
                    <span>Imprimir Etiqueta</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="removerTicket(props.row)"
                  >
                    <Icon
                      :style="{fontSize: '16', marginTop: '-3px'}"
                      icon="material-symbols:delete-outline"
                    />
                    <span>DAR DE BAJA</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Mostrar
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '15', '20']"
                    class="mx-1"
                    @input="value => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap"> de {{ props.total }} registros </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="value => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
      <div>
        <b-modal
          v-model="verDialogoDetalle"
          title="Detalle de Luminaria"
          size="lg"
          ok-only
          ok-title="Aceptar"
          no-close-on-backdrop
        >
          <detalle-luminaria
            :luminaria="luminaria"
            :potencias="potencias"
            :tipos-lampara="tiposLamparas"
            :pruebas-lampara="pruebasLampara"
            :origen="origen"
            @actualizar="loadReport"
          />
        </b-modal>
      </div>
      <div>
        <b-modal
          id="modal-etiqueta"
          v-model="etiquetaModal"
          title="Vista Previa de Etiqueta"
          size="lg"
          ok-only
          ok-title="Aceptar"
          no-close-on-backdrop
          hide-footer
        >
          <etiqueta :luminaria="luminaria" />
        </b-modal>
      </div>
      <div>
        <b-modal
          id="modal-selection"
          v-model="verModalRemover"
          title="Vista Previa de Item a Remover"
          size="lg"
          ok-only
          ok-title="Aceptar"
          no-close-on-backdrop
          hide-footer
        >
          <componente-remover
            origen="LUMINARIAS"
            titulo-origen="esta Luminaria"
            :item="luminaria"
            @actualizar="loadReport"
            @cerrar-modal="cerrarModalRemover"
          />
        </b-modal>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BButton,
  VBTooltip,
  BOverlay,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormDatepicker,
  BFormSelect,
  BPagination,
  BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { getlistado } from '@/utils/catalogos'
import { isMobile } from '@/utils/funciones'
import {
  calcularFecha, formatDate, obtenerFechaInicioFin, sumarDiasFecha,
} from '@/utils/fechas'
import {
  findLuminariasLab,
  getUtilsLaboratorio,
} from '@/utils/laboratorio/luminarias'
import DetalleLuminaria from '@/components/laboratorio/luminarias/detalleLuminaria.vue'
import ModalIngresoLuminarias from '@/components/laboratorio/luminarias/modalIngresoLuminarias.vue'
import { getUsersByCompany } from '@/utils/usuarios'
import ComponenteAsignacion from '@/components/laboratorio/componenteAsignacion.vue'
import { generarReporteDespachos } from '@/utils/bodega/reporteDespachosGeneral'
import { notificacionError, notificacionInformativa } from '@/utils/mensajes'
import { getListadoFallasLuminaria } from '@/utils/mantenimiento/bodega/bodegaUtils'
import Etiqueta from '@/components/mantenimiento/bodega/etiqueta.vue'
import ReporteGeneral from '@/components/reportes/reporteGeneral.vue'
import {
  subtitulo, tituloDescarga, tituloPrincipal, tituloSecundario, getColumns, getRows,
} from '@/utils/bodega/luminarias-reporte-excel'
import ComponenteRemover from '@/components/bodega/componenteRemover.vue'

export default {
  name: 'Index',
  components: {
    ComponenteRemover,
    Etiqueta,
    ReporteGeneral,
    ComponenteAsignacion,
    ModalIngresoLuminarias,
    DetalleLuminaria,
    BRow,
    BCol,
    BModal,
    BBadge,
    vSelect,
    BButton,
    BOverlay,
    BDropdown,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BPagination,
    BFormSelect,
    VueGoodTable,
    BDropdownItem,
    BFormDatepicker,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      verModalRemover: false,
      loadingDespacho: false,
      etiquetaModal: false,
      origen: '',
      primerCodigoExtraido: '',
      segundoCodigoExtraido: '',
      textoEscaneado: '',
      estados: '',
      calcularFecha,
      loading: false,
      tecnicos: [],
      verDialogoDetalle: false,
      pageLength: 15,
      tipoLampara: null,
      etapaBusqueda: null,
      luminaria: null,
      grupos: [],
      opcionesReporte: [],
      tiposLamparas: [],
      tipoTornillos: [],
      potencias: [],
      pruebasLampara: [],
      fechaInicio: '',
      fechaFin: '',
      rows: [],
      etapas: [
        { title: 'Todas', value: '' },
        { title: 'Ingresadas', value: 'INGRESADA' },
        { title: 'Pruebas', value: 'PRUEBAS' },
        { title: 'Listas para despacho', value: 'DESPACHO' },
        { title: 'Despachadas', value: 'DESPACHADA' },
        { title: 'En instalación', value: 'INSTALACION' },
        { title: 'Instaladas', value: 'INSTALADA' },
      ],
      columns: [
        {
          label: 'Tipo Lámpara',
          field: 'tipoLampara.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Potencia',
          field: 'potencia.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Serie',
          field: 'serie',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Tipo',
          field: 'tipo',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'estado',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fecha Creación',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      columnsLuminaria: [
        {
          label: 'Tipo Lámpara',
          field: 'tipoLampara.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Potencia',
          field: 'potencia.nombre',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Serie',
          field: 'serie',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Tipo',
          field: 'tipo',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'estado',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Grupo',
          field: 'grupo',
          width: '220px',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Mejora Realizada',
          field: 'mejora',
          width: '320px',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fecha Creación',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Opciones',
          field: 'opciones',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      columnasSeleccionadas: [],
      origenReporte: {
        origen: 'LUMINARIAS', nombre: 'Luminarias', concordancia: 'las', concordanciaDos: 'establecidas',
      },
      columnsReporte: [],
      rowsReporte: [],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    isMobile() {
      return isMobile()
    },
    tituloPrincipal() {
      return tituloPrincipal
    },
    tituloSecundario() {
      return tituloSecundario
    },
    subtitulo() {
      return subtitulo
    },
    tituloDescarga() {
      return tituloDescarga
    },
  },
  mounted() {
    document.addEventListener('keypress', this.manejarTeclaPresionada)
  },
  beforeDestroy() {
    document.removeEventListener('keypress', this.manejarTeclaPresionada)
  },
  async created() {
    try {
      let columns = this.usuario.company.name === 'APMIX' ? this.columns : this.columnsLuminaria
      if (this.usuario.company.name === 'APVN') {
        this.etapas.splice(5, 2)
      }
      if (this.usuario.company.name !== 'APMIX') {
        columns = columns.filter(column => column.field !== 'grupo' && column.field !== 'mejora')
      }

      this.columnasSeleccionadas = columns
      this.loading = true
      this.rows = []
      this.origen = this.usuario.company.name
      const fechas = obtenerFechaInicioFin()
      this.fechaFin = fechas.fechaFin
      this.fechaInicio = fechas.fechaInicio
      await this.cargarCatalogos()
      await this.loadReport()
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  },
  methods: {
    removerTicket(params) {
      const luminaria = JSON.parse(JSON.stringify(params))
      delete luminaria.vgt_id
      delete luminaria.originalIndex
      if (luminaria) {
        this.luminaria = luminaria
        this.verModalRemover = true
      }
    },
    cerrarModalRemover() {
      this.verModalRemover = false
    },
    async loadReport() {
      this.loading = true
      this.verDialogoDetalle = false
      const fechaInicio = new Date(this.fechaInicio)
      const fechaFin = sumarDiasFecha(new Date(this.fechaFin), 1)

      const filter = {
        where: {
          and: [
            { companyId: this.usuario.company.id },
            { fechaCreacion: { gte: fechaInicio.toISOString() } },
            { fechaCreacion: { lte: fechaFin.toISOString() } },
          ],
        },
        order: ['fechaCreacion DESC'],
      }

      if (this.etapaBusqueda && this.etapaBusqueda.value !== '') {
        if (this.etapaBusqueda.value !== 'TODOS') {
          filter.where.and.push({
            estado: this.etapaBusqueda.value,
          })
        }
      }
      if (this.tipoLampara && this.tipoLampara.value !== '') {
        filter.where.and.push({ 'tipoLampara.id': this.tipoLampara.id })
      }

      const data = await findLuminariasLab(filter)
      this.rowsReporte = await getRows([...data])
      // eslint-disable-next-line no-restricted-syntax
      this.loading = false
      this.rows = data
      this.columnsReporte = getColumns()
    },
    async imprimirEtiqueta(params) {
      if (params) {
        this.luminaria = params
        this.etiquetaModal = true
      }
    },
    manejarTeclaPresionada(e) {
      const isQRTextarea = e.target.classList.contains('qr-textarea')
      const isTableSearchInput = e.target.classList.contains('vgt-input')
      if (!isQRTextarea && !isTableSearchInput) {
        const input = document.querySelector('.textoQr')
        if (input) {
          input.focus()
          input.value += e.key
          e.preventDefault()
        }
      }
    },
    async buscarPorSerieTipo(filtro = null) {
      this.loading = true
      this.verDialogoDetalle = false

      const filter = filtro || {
        where: {
          companyId: this.usuario.company.id,
          serie: this.primerCodigoExtraido,
          tipo: this.segundoCodigoExtraido,
        },
      }

      const data = await findLuminariasLab(filter)
      this.loading = false
      this.rows = data
    },
    extraerCodigos() {
      const removerPrimerCodigo = /P-N[Ññ]\s*([^\s]+)/
      const removerSegundoCodigo = /S-N[Ññ]\s*([^\s]+)/

      const matchPrimerCodigo = this.textoEscaneado.match(removerPrimerCodigo)
      const matchSegundoCodigo = this.textoEscaneado.match(removerSegundoCodigo)

      if (matchPrimerCodigo && matchSegundoCodigo) {
        // eslint-disable-next-line prefer-destructuring
        this.primerCodigoExtraido = matchPrimerCodigo[1]
        // eslint-disable-next-line prefer-destructuring
        this.segundoCodigoExtraido = matchSegundoCodigo[1]

        const filtro = {
          where: {
            serie: matchSegundoCodigo[1],
            tipo: matchPrimerCodigo[1],
            companyId: this.usuario.company.id,
          },
        }

        this.buscarPorSerieTipo(filtro)
        this.textoEscaneado = null
        this.primerCodigoExtraido = null
        this.segundoCodigoExtraido = null
      } else {
        console.error('Texto del escáner no válido')
      }
    },
    estadoSeguimiento(estado) {
      return this.estados[estado]?.nombre || estado
    },
    async showDetalle(params) {
      const luminaria = params.row
      if (luminaria) {
        console.log(luminaria.id)
        this.luminaria = luminaria
        this.verDialogoDetalle = true
      }
    },
    getColorClass(row) {
      if (row && row.estado === 'INGRESADA') {
        return 'light-danger'
      } if (row && row.estado === 'PRUEBAS') {
        return 'light-info'
        // eslint-disable-next-line no-mixed-operators
      } if (row && row.estado === 'DESPACHO' || row && row.estado === 'GARANTIA' || row && row.estado === 'REPARACION') {
        return 'light-warning'
      } if (row && row.estado === 'DESPACHADA') {
        return 'light-primary'
      } if (row && row.estado === 'MANTENIMIENTO DE LUMINARIA') {
        return 'light-secondary'
      } if (row && row.estado === 'LUMINARIA DESPACHADA') {
        return 'light-success'
      } if (row && row.estado === 'INSTALACION') {
        return 'light-info'
      } if (row && row.estado === 'INSTALADA') {
        return 'light-success'
      } if (row && row.estado === 'DESCARTADA') {
        return 'danger'
      }
      return ''
    },
    async generarDocumentoDespacho() {
      this.loadingDespacho = true
      try {
        const fechaInicio = new Date(this.fechaInicio)
        const fechaFin = sumarDiasFecha(new Date(this.fechaFin), 1)
        const filter = {
          where: {
            and: [
              { companyId: this.usuario.company.id },
              { fechaCreacion: { gte: fechaInicio.toISOString() } },
              { fechaCreacion: { lte: fechaFin.toISOString() } },
              { estado: { inq: ['DESPACHADA', 'INSTALACION', 'INSTALADA'] } },
            ],
          },
          order: ['fechaCreacion DESC'],
        }
        const despachadas = await findLuminariasLab(filter)
        const fileResumen = await generarReporteDespachos(despachadas, this.origenReporte, this.usuario)
        if (fileResumen !== null) {
          const linkSource = `data:application/pdf;base64,${fileResumen.Base64}`
          const downloadLink = document.createElement('a')
          const fileName = `Despacho de ${this.origenReporte.nombre} ${formatDate(new Date(), '/')}.pdf`
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
        }
        notificacionInformativa('Documento Descargado', `Reporte de despacho de ${this.origenReporte.nombre}`)
      } catch (error) {
        console.error(error)
        notificacionError('Documento no descargado', 'Inconvenientes al intentar descargar')
      } finally {
        this.loadingDespacho = false
      }
    },
    async cargarCatalogos() {
      try {
        const [
          potencias,
          tiposLamparas,
          pruebasLampara,
          tecnicos,
          estados,
          grupos,
          opcionesReporte,
        ] = await Promise.all([
          getlistado(25, 'Potencias Lámparas', false, this.usuario),
          getlistado(9, 'Tipo de Lámparas', false, this.usuario),
          getlistado(23, 'Pruebas Lámparas Bodega', false, this.usuario),
          getUsersByCompany(this.usuario.company.id),
          getUtilsLaboratorio(3),
          getlistado(12, 'Grupos', false, this.usuario),
          getListadoFallasLuminaria(7),
        ])
        potencias.sort((a, b) => a.valor - b.valor)

        tecnicos.map(usuario => {
          // eslint-disable-next-line no-param-reassign
          usuario.nombre = `${usuario.firstName} ${usuario.firstSurname}`
          return usuario
        })

        this.potencias = potencias
        this.tiposLamparas = tiposLamparas
        this.pruebasLampara = pruebasLampara
        this.tecnicos = tecnicos
        this.estados = estados
        this.grupos = grupos
        this.opcionesReporte = opcionesReporte
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>
<style scoped>
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 1.5s linear infinite;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
