import config from '@/utils/config'
import RequestAdmin from '@/utils/request'

const estados = {
  INGRESADA: {
    nombre: 'INGRESADA / CONFIRMACIÓN DE DATOS',
    value: 'INGRESADA',
  },
  PRUEBAS: {
    nombre: 'PRUEBAS',
    value: 'PRUEBAS',
  },
  GARANTIA: {
    nombre: 'REPARACIÓN / GARANTÍA',
    value: 'GARANTIA',
  },
  DESPACHO: {
    nombre: 'LISTA PARA DESPACHO',
    value: 'DESPACHO',
  },
  DESPACHADA: {
    nombre: 'DESPACHADA',
    value: 'DESPACHADA',
  },
  INSTALACION: {
    nombre: 'INSTALACIÓN',
    value: 'INSTALACION',
  },
  INSTALADA: {
    nombre: 'INSTALADA',
    value: 'INSTALADA',
  },
  REPARACION: {
    nombre: 'REPARACIÓN',
    value: 'REPARACION',
  },
}

const destinosLaboratorio = [
  {
    id: 1,
    nombre: 'Pruebas Exitosas',
  },
  {
    id: 2,
    nombre: 'Pruebas Fallidas',
  },
]

const inspecciones = [
  {
    id: 1,
    nombre: 'ENCIENDE NO COMUNICA',
    companyId: '63518099fb6b023eb2b9f20b',
    user: 'pruebas@apvnsa.com',
    active: true,
  },
  {
    id: 2,
    nombre: 'LED QUEMADOS',
    companyId: '63518099fb6b023eb2b9f20b',
    user: 'pruebas@apvnsa.com',
    active: true,
  },
  {
    id: 3,
    nombre: 'CONECTORES QUEMADOS',
    companyId: '63518099fb6b023eb2b9f20b',
    user: 'pruebas@apvnsa.com',
    active: true,
  },
  {
    id: 14,
    nombre: 'CONECTORES EN MAL ESTADO',
    companyId: '63518099fb6b023eb2b9f20b',
    user: 'pruebas@apvnsa.com',
    active: true,
  },
]

export function getColorVariantLuminarias(item) {
  if (item && item.estado === 'INGRESADA') {
    return 'danger'
  } if (item && item.estado === 'PRUEBAS') {
    return 'info'
    // eslint-disable-next-line no-mixed-operators
  } if (item && item.estado === 'DESPACHO' || item && item.estado === 'GARANTIA' || item && item.estado === 'REPARACION') {
    return 'warning'
  } if (item && item.estado === 'DESPACHADA') {
    return 'primary'
  } if (item && item.estado === 'MANTENIMIENTO DE LUMINARIA') {
    return 'secondary'
  } if (item && item.estado === 'LUMINARIA DESPACHADA') {
    return 'success'
  } if (item && item.estado === 'INSTALACION') {
    return 'info'
  } if (item && item.estado === 'INSTALADA') {
    return 'success'
  } if (item && item.estado === 'DESCARTADA') {
    return 'danger'
  }
  return ''
}

// eslint-disable-next-line consistent-return
export function getUtilsLaboratorio(tipo) {
  if (tipo === 1) return destinosLaboratorio
  if (tipo === 2) return inspecciones
  if (tipo === 3) return estados
}

const url = `${config.URL_BASE}luminarias`

// eslint-disable-next-line import/prefer-default-export
export async function updateCreateLuminariaLab(body, tipo) {
  try {
    const request = new RequestAdmin()
    if (tipo === 1) {
      return request.executePostPutPatch(`${url}/${body.id}`, body, 'PATCH')
    }
    // eslint-disable-next-line no-param-reassign
    delete body.id
    return request.executePostPutPatch(`${url}`, body, 'POST')
  } catch (err) {
    console.error('Error en Crear / Editar Luminaria', err)
    return null
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function findLuminariasLab(filter) {
  try {
    const request = new RequestAdmin()
    const urrl = `${url}?filter=${JSON.stringify(filter)}`
    return request.executeGet(urrl)
  } catch (err) {
    console.error('Error en obtener Luminarias', err)
    return null
  }
}

export async function countLuminariasLab(where) {
  try {
    const request = new RequestAdmin()
    return request.executeGet(`${url}/count?where=${JSON.stringify(where)}`)
  } catch (err) {
    console.error('Error en obtener Luminarias', err)
    return null
  }
}

export async function getLuminariasLab(id) {
  try {
    if (id === null || id === undefined) return null
    const request = new RequestAdmin()
    return await request.executeGet(`${url}/${id}`)
  } catch (err) {
    console.error('Error en cargar Luminarias', err)
    return null
  }
}
